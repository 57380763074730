import { INumericIdentifierModel, IRI, ITeamUpload, IUpdatedAtAndBy, } from "@api/schema"

// #region API definitions

/**
 * Module-Interface to avoid using specific Interface like IProject for owner property in interface IDocument.
 * @see https://futureprojects.atlassian.net/browse/FCP-1965
 */
export interface IDocumentOwner<VisibilityType = any> {
  id?: number
  "@id"?: IRI
  "@ype"?: string
  locked?: boolean
  // NOTE: visibility property may be wrong for other owner types
  visibility?: VisibilityType
}

/**
 * Structure of a Document
 */
export interface IDocument<Owner extends IDocumentOwner<any> = IDocumentOwner<any>> extends INumericIdentifierModel, IUpdatedAtAndBy {
  /**
   * Backend set type of the IDocument, e.g. App\Entity\Document
   */
  "@type"?: string
  /**
   * Category of the document (type of a Document), e.g. "businessplan", "other".
   */
  category?: DocumentsCategory
  /**
   * This the entity the document belongs to.
   */
  owner?: Owner
  /**
   * Type of the used template.
   *
   * NOTE: the backend does not validate the template types, because it does not know them.
   * So they are simple strings, coming from the backend, should be validated as DocumentTemplateCategory
   * in the client.
   */
  template?: string
  /**
   * Title of the document.
   */
  title?: string
  /**
   * Description of the document. Not printed to the PDF, for team usage only.
   */
  description?: string
  /**
   * Is this document the "primary" of its type?
   * E.g. necessary to decide, which document is served to an ExternalProvider, e.g. SINN,
   * when a document of type "businessplan" is requested.
   */
  isPrimary?: boolean
  /**
   * Last PDF file created from that document.
   */
  pdfFile?: ITeamUpload
  /**
   * Attachments of a document are added in Version 2.
   */
  // attachments?: any[] // TODO: t.b.d.
}

/**
 * Structure of the template data stored for a chapter.
 */
interface IChapterTemplateDto {
  /**
   * Backend set type of the IChapter, e.g. "ChapterTemplateDto_v1"
   */
  "@type"?: string
  /**
   * Name of the chapter template, which is originally used to create the chapter.
   * May be empty, but only if the document template is empty too.
   */
  chapter: string
  /**
   * Name of the document template, to which the chapter belongs to.
   * May be empty, but only if the chapter template is empty too.
   */
  document: string
}

/**
 * Structure of a Chapter
 */
export interface IChapter<Document extends IDocument = IDocument> extends INumericIdentifierModel, IUpdatedAtAndBy {
  /**
   * Template data, a chapter is based on.
   */
  template?: IChapterTemplateDto
  /**
   * Title of the chapter.
   */
  title?: string
  /**
   * Content of the chapter.
   * May contain HTML.
   */
  content?: string
  /**
   * Checklist to mark, what aspects of a chapter is finished.
   */
  checklist?: IChapterChecklistDto[]
  /**
   * Parent document, to which the chapter belongs.
   */
  document?: Document
  /**
   * Order position of the chapter within the list of all chapters in the document.
   */
  sortOrder?: number
}

/**
 * States, a Checklist item may have.
 *
 * NOTE: constants are defined by the backend, do not change without changing them there!
 */
export enum ChecklistItemState {
  /** Task is open, is not done yet (unchecked). */
  Open = "open",
  /** Task is done (checked). */
  Done = "done",
  /** Task is to be ignored, e.g. because it is not relevant. */
  Ignored = "ignored",
}

/**
 * Current backend type name of the checklist DTO.
 */
export const CURRENT_CHAPTER_CHECKLIST_TYPE = 'ChapterChecklistDto_v1'

/**
 * Current backend type name of the chapter template DTO.
 */
export const CURRENT_CHAPTER_TEMPLATE_TYPE = "ChapterTemplateDto_v1"

/**
 * Structure of a user defined checklist item.
 */
export interface IChapterChecklistDto {
  /**
   * Backend set type of the IChapterChecklist, e.g. "ChapterChecklistDto_v1"
   */
  '@type': string
  /**
   * Description of the checklist item
   */
  task: string
  /**
   * Type of the checklist item:
   * Is it defined by the user or by the used template?
   */
  type: "user" | "template"
  /**
   * State of the checklist item: Is it done or not?
   */
  state: ChecklistItemState
}

// #endregion API definitions


// #region document categories

/**
 * Known document categories.
 *
 * DO NOT CHANGE existing category names, as they are stored in the database to recognize the category of documents.
 * Changing the name means, that Document types may not longer be recognized.
 */
export enum DocumentsCategory {
  /**
   * Businessplan document categories.
   */
  Businessplan = "businessPlan",
  Canvas = "canvas",
  /**
   * Document category that does not match any other category.
   */
  Other = "other",
}

// #endregion