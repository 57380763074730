/* eslint-disable max-classes-per-file */

import { TranslationQuery } from "next-translate"
import useTranslation from "next-translate/useTranslation"

import { logUnhandledUnexpectedError } from "@basics/errors"
import { DEBUG_LOG_UNTRANSLATED_I18N_KEYS } from "config"


/**
 * The character that separates i18n namespace from i18n key.
 */
export const namespaceSeparator = ":" // equals next-translate's `nsSeparator`

/**
 * The "old" "default" "fallback" namespace.
 * Note that namespace and it's shortcut are identical (and should stay so).
 */
export const defaultNamespace: NamespaceShortcut = "common"

/**
 * A string that is multi-lingual and shows the user that a certain text is missing and is being loaded.
 * Note that is must work w/o i18n translation.
 */
export const fallbackStringWhenLoading = "💬⏳"

/**
 * Extracts the i18n namespace of a fully qualified i18n key.
 *
 * @param key A fully qualified / complete i18n key
 * @returns The namespace of this key, or null if not found
 */
const namespaceFromI18nKey = (key: string): string => {
  // regex explained:
  // (capture-group) [character class] ^=not; *="0 or more"; .="any character"
  // We look for a string of length >=0 that does not contain the namespaceSeparator (e.g. ":"): [^:]*
  // We want to refer to this string, so we put it into () - called "capture group": ([^:]*)
  // We want the capture group to contain everything until the first occurrence of namespaceSeparator, so we add: :
  // After the namespaceSeparator we allow any characters so the regex matches the complete key: .*
  // With a namespaceSeparator of ":", the regex will be "([^:]*):.*"
  const regex = new RegExp("([^" + namespaceSeparator + "]*)" + namespaceSeparator + ".*")
  const matches = regex.exec(key)
  // matches contains an array of matches (with the complete string at index [0]), or null if it does not match
  // we want to get the first capture group at index [1]
  return matches ? matches[1] : null
}

/**
 * Extracts the i18n namespace of a fully qualified i18n key.
 * If key does not contain a namespace, we fallback to `defaultNamespace`/`common`, as this is the "old" namespace containing "everything".
 *
 * @todo check if we want to replace the default return value `common` with `default` or `null` after complete refactoring of `common.json`
 *
 * @param key A fully qualified / complete i18n key
 * @returns The namespace of this key, for default see `defaultNamespace` (most probable "common")
 */
export const namespaceFromI18nKeyWithDefaultFallback = (key: string): string =>
  namespaceFromI18nKey(key) || defaultNamespace

/**
 * Interface that defines the combination of a namespace and a key.
 */
export interface Ii18NKey {
  namespace: NamespaceShortcut
  key: string
}

/**
 * The list of all known i18n namespaces.
 * Consists of "common" namespaces that are used by multiple components,
 * and specific namespaces for usecases and entities.
 *
 * NOTE: we decided against more explicit "type-safe" definitions like using
 * EntityType or a dedicated Usecases enum for two reasons:
 * - shortcuts are much more readable, and coders are forced (and allowed) to use the recognizable strings
 * - it's apparently not possible to define a dynamic/calculated array `as const`
 */
const NamespaceShortcutList = [
  "default",
  /**
   * The "old" "default" "fallback" namespace.
   * Note that namespace and it's shortcut are identical (and should stay so).
   */
  "common",
  "error",

  // "static" stuff - no usecases, no entities, but also no "global"
  "base-layout",
  "data-protection",
  "faq",
  "faq-meta",
  "imprint",
  "netiquette",
  "terms-of-use",

  // usecases - defined here (should be in-line with usecase names at other places)
  "action-request-operations", // TODO rename to camelCase: actionRequest-operations
  "address-operations",
  "category-operations",
  "document-operations",
  "transition",
  "events",
  "external-provider",
  "feedback-operations",
  "follow-project",
  "goto",
  "idea-operations",
  "map",
  "market",
  "my-projects", // TODO rename to camelCase: myProjects; check if it should be merged with "project-operations"
  "network-project",
  "onboarding",
  "partner-market",
  "platform",
  "platform-manager",
  "project-operations",
  "project-workflow",
  "program-operations",
  "provider-operations",
  "pubtools",
  "report-entity",
  "sharing",
  "single-multi",
  "system",
  "user-operations",
  "userObjectRole-operations",
  "verification",
  "video",

  // EntityTypes - reused from EntityType enum (please try to keep names in-line with schema.ts)
  "address",
  "category",
  "chapter",
  "document",
  "idea",
  "program",
  "project",
  "projectFollowership",
  "provider",
  "supportRequest",
  "user",
  "userObjectRole",
  "sdg",

  // document templates
  "businessplan",
  "canvas",
] as const

/**
 * The type of the list of all known i18n namespaces.
 * To add namespaces follow the steps in doc/principles/i18n.md.
 */
export type NamespaceShortcut = typeof NamespaceShortcutList[number]

/**
 * This type ensures that there will be a filename/path entry for every NamespaceShortcut
 */
type NamespacePathMap = {
  [key in NamespaceShortcut]: string
}

/**
 * A map from namespace shortcuts to actual paths (without trailing `.json`).
 *
 * Note: We don't build paths using specific functions to enhance readability,
 * i.e. no `usecaseNamespacePrefix + namespacePathSeparator + Usecase.FollowProject`
 * nor `i18nNamespace(Usecase.MyProjects)`.
 *
 * @todo test the existence of files (add ".json" extension)
 */
export const NamespacePath: NamespacePathMap = {
  ["default"]: "default",
  ["common"]: "common",
  ["error"]: "error",
  ['goto']: "goto",
  ['system']: "usecases/system",

  ["base-layout"]: "common/base-layout",
  ["data-protection"]: "data-protection",
  ["faq"]: "common/faq",
  ["faq-meta"]: "common/faq-meta",

  ["imprint"]: "static/imprint",
  ["netiquette"]: "static/netiquette",
  ["terms-of-use"]: "static/terms-of-use",

  ["action-request-operations"]: "usecases/action-request-operations",
  ["document-operations"]: "usecases/document-operations",
  ["category-operations"]: "usecases/category-operations",
  ["transition"]: "usecases/transition",
  ["events"]: "usecases/events",
  ["external-provider"]: "usecases/external-provider",
  ["feedback-operations"]: "usecases/feedback-operations",
  ["follow-project"]: "usecases/follow-project",
  ["idea-operations"]: "usecases/idea-operations",
  ["map"]: "usecases/map",
  ["market"]: "usecases/market",
  ["my-projects"]: "usecases/my-projects",
  ["network-project"]: "usecases/network-project",
  ["onboarding"]: "usecases/onboarding",
  ["partner-market"]: "usecases/partner-market",
  ["platform-manager"]: "usecases/platform-manager",
  ["address-operations"]: "usecases/address-operations",
  ["project-operations"]: "usecases/project-operations",
  ["userObjectRole-operations"]: "usecases/userObjectRole-operations",
  ["project-workflow"]: "usecases/project-workflow",
  ["program-operations"]: "usecases/program-operations",
  ["provider-operations"]: "usecases/provider-operations",
  ["platform"]: "usecases/platform",
  ["pubtools"]: "usecases/pubtools",
  ["report-entity"]: "usecases/report-entity",
  ["sharing"]: "usecases/sharing",
  ["single-multi"]: "usecases/single-multi",
  ["user-operations"]: "usecases/user-operations",
  ["verification"]: "usecases/verification",
  ["video"]: "usecases/video",

  ["address"]: "entityTypes/address",
  ["document"]: "entityTypes/document",
  ["chapter"]: "entityTypes/chapter",
  ["category"]: "entityTypes/category",
  ["idea"]: "entityTypes/idea",
  ["program"]: "entityTypes/program",
  ["project"]: "entityTypes/project",
  ["projectFollowership"]: "entityTypes/projectFollowership",
  ["provider"]: "entityTypes/provider",
  ['supportRequest']: "entityTypes/supportRequest",
  ['user']: "entityTypes/user",
  ['userObjectRole']: "entityTypes/userObjectRole",
  ["sdg"]: "entityTypes/sdg",

  ["businessplan"]: "document-templates/businessplan",
  ["canvas"]: "document-templates/canvas",
}

/**
 * Creates a complete i18n key from given namespace shortcut and key.
 * Required by methods that want to create a fully qualified key to pass it further.
 * If you want to translate the key, use `useDynamicTranslation` instead.
 *
 * @param namespaceShortcut Namespace shortcut, one of `NamespaceShortcut` in src/services/i18n.ts
 * @param key The I18n key without a namespace prefix
 * @returns The fully qualified key, with a namespace prefix
 */
export const prefixedKey = (namespaceShortcut: NamespaceShortcut, key: string): string =>
  NamespacePath[namespaceShortcut] + namespaceSeparator + key

/**
 * The type of our dynamic translate function.
 *
 * This redefinition extends the original t function signature with an additional `namespaceShortcut` parameter.
 *
 * NOTE: original t function's generic type param is defined as <T extends unknown = string>, but typescript complains:
 * Constraining the generic type `T` to `unknown` does nothing and is unnecessary. eslint@typescript-eslint/no-unnecessary-type-constraint
 */
export type DynamicTranslate = <T = string>(
  namespaceShortcut: NamespaceShortcut,
  key: string,
  params?: TranslationQuery,
  options?: {
    /**
     * To return all sub elements of the given key within the given namespace
     */
    returnObjects?: boolean
    fallback?: string | string[]
    default?: T | string
    ns?: string
  }
) => T


/**
 * Returns a function that translates the given i18n key using the given namespace shortcut,
 * or using the key as is if namespaceShortcut is null. In this case and when the key does not
 * have an embedded namespace, the `defaultNamespace` is used as namespace.
 *
 * The returned function should be used just like `t` from `useTranslation` (next-translate's Translate).
 *
 * The signature of the returned function is as follows:
 * - param namespaceShortcut Namespace shortcut, one of the list in src/services/i18n.ts, or null
 * - param key The I18n key without a namespace prefix, or a complete i18n key
 * - param params A indexed list with symbols to be replaced in the translated text
 * - param options Additional options
 * - returns the translated text
 *
 * IMPORTANT NOTE this only works if it's enclosed within a `DynamicNamespaces` component,
 * e.g. by using `withDynamicNamespaces` higher order component.
 *
 * @returns A translating function that calls `useTranslation().t()` with a fully and correctly qualified i18n key.
 */
export const useDynamicTranslation = (): DynamicTranslate => {
  const { t } = useTranslation()
  return <T = string>(
    namespaceShortcut: NamespaceShortcut,
    key: string,
    params?: TranslationQuery,
    options?: {
      returnObjects?: boolean
      fallback?: string | string[]
      default?: T | string
      ns?: string
    }
  ) => {
    const untranslated = namespaceShortcut
      ? prefixedKey(namespaceShortcut, key)
      : key.includes(namespaceSeparator)
        ? key
        : defaultNamespace + namespaceSeparator + key
    const translated = t(untranslated, params, options)
    if (DEBUG_LOG_UNTRANSLATED_I18N_KEYS &&
      typeof translated === "string" &&
      translated === untranslated) {
      logUnhandledUnexpectedError("untranslated key " + untranslated, 'i18n')
    }

    return translated
  }
}

/**
 * Adds a namespace prefix if not present.
 *
 * NOTE/@todo: this method/approach should become unnecessary when the new i18n model is fully adapted.
 *
 * @param namespaceShortcut Namespace shortcut, one of the list in src/services/i18n.ts, that should be used if keys lacks it
 * @param key The I18n key with or without a namespace prefix
 * @returns A key that has a namespace; either the original one, or if that was lacking, the one from namespaceShortcut
 */
export const addNamespacePrefixIfNotPresent = (namespaceShortcut: NamespaceShortcut, key: string): string => {
  const ns = namespaceFromI18nKey(key)
  return ns
    ? key
    : prefixedKey(namespaceShortcut, key)
}